import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@data-access/services';
import { AdminSideMenuComponent } from "@components/shared/admin-side-menu/admin-side-menu.component";
import { CommonModule } from '@angular/common';
import { HeaderComponent } from "../header/header.component";
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-admin-layout',
  standalone: true,
  imports: [AdminSideMenuComponent, CommonModule, HeaderComponent, TooltipModule],
  templateUrl: './admin-layout.component.html',
  styleUrl: './admin-layout.component.css'
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  isSidebarCollapsed = false;
  isLargeScreen = true;
  private resizeObserver: ResizeObserver;

  constructor(private router: Router, private authService: AuthService) {
    // Initialize ResizeObserver
    this.resizeObserver = new ResizeObserver(entries => {
      this.checkScreenSize();
    });
  }

  ngOnInit() {
    // Start observing window size changes
    this.resizeObserver.observe(document.body);
    this.checkScreenSize();
  }

  ngOnDestroy() {
    // Clean up the observer when component is destroyed
    this.resizeObserver.disconnect();
  }

  private checkScreenSize() {
    this.isLargeScreen = window.innerWidth >= 1024; // 1024px is the 'lg' breakpoint in Tailwind
  }

  toggleSidebar() {
    if (this.isLargeScreen) {
      this.isSidebarCollapsed = !this.isSidebarCollapsed;
    }
  }

  navigate(event: Event, link: string) {
    event.preventDefault();
    if (link !== '') {
      this.router.navigate([link]);
    }
  }

  logout() {
    this.authService.logOut();
    this.router.navigate(['login']);
  }
}
