<div class="flex flex-col py-0.5 sm:px-1">
    <div class="flex justify-between items-center group category_item_div"  (click)="clickToActive(index)" 
      >
      <div class="flex items-center space-x-2">
        <span class="pi pi-{{icon}} text-{{iconColor}}"></span>
        <span class="text-{{textColor}} flex justify-end group-hover:text-white">{{menuName}}</span>
      </div>
      <span class="text-[{{iconColor}}] opacity-50 group-hover:text-white">
        {{menuProp}}
      </span>
    </div>
  </div>