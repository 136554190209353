import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-text-input-field',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './text-input-field.component.html',
})
export class TextInputFieldComponent {
  @Input() formControl: FormControl = new FormControl('');
  @Input() label: string = '';
  @Input() inputId: string = '';
  @Input() placeHolder: string = '';
  @Input() formSubmitted: boolean = false;
  @Input() inputType: string = 'text';
  // @Output() valueChange = new EventEmitter<string>();

  showError: boolean = false;
  @Input() disabled: boolean = false;

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes['formSubmitted'] || changes['formControl']) {
  //     this.updateErrorState();
  //   }
  // }

  // private updateErrorState() {
  //   this.showError ?? false = (this.formControl?.invalid && (this.formControl?.touched || this.formSubmitted));
  // }
  // get value(): string {
  //   return this.formControl.value;
  // }

  // set value(val: string) {
  //   this.formControl.setValue(val);
  //   this.valueChange.emit(val);
  // }
}
